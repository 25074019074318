<template>
  <v-app id="inspire">
    <v-app-bar app clipped-left flat color="primary" class="header">
      <v-container class="container_principal_pedido">
        <v-row>
          <v-col cols="9" class="logoConfigurarPedido" align="left">
            <a href="/">
              <img src="@/assets/logo_blanco.svg" class="logoBlanco" />
            </a>
          </v-col>
          <v-col
            cols="3"
            class="botonCambiarIdiomaWebCOnfigurarPedido"
            align="right"
          >
            <v-btn
              class="botonCambiarIdiomaPagina"
              v-if="this.$i18n.locale === 'gl'"
              @click="castellano()"
              ><span style="font-weight: lighter">ES</span> |
              <strong>GL</strong></v-btn
            >
            <v-btn
              class="botonCambiarIdiomaPagina"
              v-if="this.$i18n.locale === 'es'"
              @click="gallego()"
              ><strong>ES</strong> |
              <span style="font-weight: lighter">GL</span></v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-main>
      <v-container class="container_principal_pedido">
        <v-row>
          <v-col cols sm="12">
            <v-row>
              <v-col cols style="text-align: justify">
                <h1 style="text-align: center">Política de Cookies</h1>
                <p style="padding-top: 25px">
                  {{ $t("politicaDeCookies.parrafo1") }}
                </p>
                <p>
                  {{ $t("politicaDeCookies.parrafo2") }}
                </p>
                <p>
                  {{ $t("politicaDeCookies.parrafo3") }}
                </p>
                <p>{{ $t("politicaDeCookies.parrafo4") }}</p>
                <p>
                  <b>{{ $t("politicaDeCookies.parrafo5.negrita") }}</b>
                  {{ $t("politicaDeCookies.parrafo5.texto") }}
                  <a
                    href="http://www.google.es/intl/es/analytics/privacyoverview.html"
                    >Politica de cookies de Google Analytics.</a
                  >
                </p>
                <p>
                  {{ $t("politicaDeCookies.parrafo6") }}
                </p>
                <p>
                  {{ $t("politicaDeCookies.parrafo7") }}
                </p>
                <p>
                  <b>{{ $t("politicaDeCookies.parrafo8.negrita") }}</b
                  >{{ $t("politicaDeCookies.parrafo8.texto") }}
                </p>
                <p>
                  <b>{{ $t("politicaDeCookies.parrafo9.negrita") }}</b
                  >{{ $t("politicaDeCookies.parrafo9.texto") }}
                </p>
                <p>
                  <b>{{ $t("politicaDeCookies.parrafo10.negrita") }}</b
                  >{{ $t("politicaDeCookies.parrafo10.texto") }}
                </p>
                <p>
                  <b>{{ $t("politicaDeCookies.parrafo11.negrita") }}</b
                  >{{ $t("politicaDeCookies.parrafo11.texto") }}
                </p>
                <p>
                  {{ $t("politicaDeCookies.parrafo12") }}
                </p>
                <p>
                  {{ $t("politicaDeCookies.parrafo13.texto")
                  }}<a
                    :href="$t('politicaDeCookies.parrafo13.enlace')"
                    target="_blank"
                    >{{ $t("politicaDeCookies.parrafo13.enlace") }}</a
                  >
                </p>
                <p>
                  {{ $t("politicaDeCookies.parrafo14.texto") }}
                  <a
                    :href="$t('politicaDeCookies.parrafo14.enlace')"
                    target="_blank"
                    >{{ $t("politicaDeCookies.parrafo14.enlace") }}</a
                  >
                </p>
                <p>
                  {{ $t("politicaDeCookies.parrafo15.texto") }}
                  <a
                    :href="$t('politicaDeCookies.parrafo15.enlace')"
                    target="_blank"
                    >{{ $t("politicaDeCookies.parrafo15.enlace") }}</a
                  >
                </p>
                <p>
                  {{ $t("politicaDeCookies.parrafo16.texto") }}
                  <a
                    :href="$t('politicaDeCookies.parrafo16.enlace')"
                    target="_blank"
                    >{{ $t("politicaDeCookies.parrafo16.enlace") }}</a
                  >
                </p>
                <p>
                  {{ $t("politicaDeCookies.parrafo17.texto")
                  }}<b>{{ $t("politicaDeCookies.parrafo17.email") }}</b>
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <!-- Footer -->
    <FooterView />
    <!-- Footer -->
  </v-app>
</template>

<script>
export default {
    name: 'App',
    components: {
        FooterView: () => import('./footer.vue')
    },
    data () {
        return {
            e6: 1,
            datosPortabilidadesValidados: true,
            datosClienteValidados: false
        }
    },
    methods: {
        castellano () {
            this.$i18n.locale = 'es'
        },
        gallego () {
            this.$i18n.locale = 'gl'
        },
        volverPortada () {
            this.$store.dispatch('setcarritoPedidosLineasMovil', '')
            this.$store.dispatch('setLineasMovil', [])

            this.$router.push('/')
        }
    },

    mounted () {
        window.scrollTo(0, 0)
    }
}
</script>
